import React from "react"

import Card from "./card"

const CardSection = ({ recommendedColor = "#34d1bf" }) => {
  return (
    <section className="container internalSection">
      <div className="mb-5 row">
        <div className="px-4 text-center col-lg-4 col-12 offset-lg-4">
          <h3 className="px-5 my-2 text-center">
            Get Best Price for Our Product
          </h3>
          <small className="text-center">
            We give you the option to decide how much you need to use
          </small>
        </div>
      </div>
      <div className="row">
        <div className="offset-lg-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
          <Card
            price="399"
            billingPeriod="month"
            name="Basic"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta."
            features={["Volutpat risus.", "Tristique.", "Egestas leo varius."]}
          />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
          <Card
            price="999"
            billingPeriod="month"
            name="Basic"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta."
            features={[
              "Rutrum iaculis id.",
              "Risus non felis.",
              "A augue dictum in justo.",
              "Rhoncus ipsum.",
            ]}
            isRecommended
            recommendedColor={recommendedColor}
          />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
          <Card
            cardText="Contact Us"
            name="Basic"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta."
            features={[
              "Unlimited project use",
              "Odio quam aenean.",
              "Odio quam aenean.",
              "Feugiat.",
            ]}
          />
        </div>
      </div>
    </section>
  )
}

export default CardSection
