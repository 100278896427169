import React from "react"
import classnames from "classnames"
import { FaArrowRight } from "react-icons/fa"

import TickIcon from "../assets/img/youMedico/tick.svg"

const Card = ({
  price,
  billingPeriod,
  cardText,
  name,
  description,
  features,
  isRecommended = false,
  recommendedColor,
}) => {
  const lastFeatureClass = (lastFeature = false) =>
    classnames("row", lastFeature ? "mb-5" : "")

  const btnClass = classnames(
    "btn",
    isRecommended ? "btn-recommended" : "btn-outline"
  )

  return (
    <div className="card">
      {isRecommended && (
        <div
          className="py-2 recommendedCard"
          style={{ backgroundColor: recommendedColor }}
        >
          <div className="col">Recommended</div>
        </div>
      )}
      <div className="card-body">
        {!cardText ? (
          <h3>
            ${price}
            <small>/{billingPeriod}</small>
          </h3>
        ) : (
          <h3>{cardText}</h3>
        )}
        <h4>{name}</h4>
        <small className="text-left">{description}</small>
        <hr
          className="p-0 mx-1 my-4 border-0 w-100"
          style={{
            backgroundColor: "gray",
            opacity: 0.2,
            height: "1px",
          }}
        />
        {features?.length > 0 &&
          features.map((feature, index) => (
            <div
              key={`${feature}-${index}`}
              className={lastFeatureClass(features.length - 1 === index)}
            >
              <div className="mb-4 col">
                <img src={TickIcon} width="25" />
                <span className="ml-3">{feature}</span>
              </div>
            </div>
          ))}
        <div className="col">
          <button
            className={btnClass}
            ref={(el) =>
              el?.style?.setProperty(
                "background-color",
                recommendedColor,
                "important"
              )
            }
          >
            <span className="pl-3 text-center pl-lg-3 pr-lg-4">
              Get Started
            </span>
            <FaArrowRight
              className="ml-5 ml-lg-5 "
              style={{ fill: isRecommended ? "#ffffff" : "#183b56" }}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Card
