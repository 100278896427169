import React from "react"
import { navigate } from "gatsby"

const DemoButton = () => {
  const handleClick = () => {
    return navigate("/request-demo")
  }

  return (
    <div className="row">
      <div className="col">
        <button
          type="button"
          className="px-5 mt-5 btn cta btn-primary"
          onClick={handleClick}
        >
          <span>Free demo</span>
        </button>
      </div>
    </div>
  )
}

export default DemoButton
